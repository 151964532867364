<template>
  <div>
    <b-modal
      v-model="show"
      :title="_.startCase(class_name)"
      title-tag="div"
      size="full"
      header-bg-variant="dark"
      header-text-variant="light"
      centered
      lazy
      @hidden="
        () => {
          $emit('close', mutated);
        }
      "
      no-close-on-backdrop
      header-class="p-2 p-sm-3"
      body-class="p-0"
    >
      <!-- no-close-on-esc -->
      <!-- size="xl" -->
      <!-- size="full" -->
      <!-- <b-container fluid> -->

      <slot name="modal_above_tabs" v-bind="{ mutate, params, loading, show }"></slot>

      <slot name="modal_view" v-bind="{ mutate, params, loading, show, mode, class_relationships }">
        <div class="p-2 p-sm-3">
          <TabView
            :class_name="class_['class_name']"
            :_rel_search_components="_rel_search_components"
            :fixed_params="fixed_params"
            :params.sync="params"
            @reload="
              params => {
                reload(params);
              }
            "
            :disabled="!edit_allowed || loading"
            :allow_columns="true"
          >
            <template v-for="slotName in class_relationships_slotNames" v-slot:[slotName]="{ relationship, params, disabled }">
              <!-- {{relationship['relationship_name']}} -->
              <slot :name="slotName" :relationship="relationship" :params.sync="params" :disabled="disabled"></slot>
            </template>
          </TabView>
        </div>
      </slot>

      <br />
      <!-- </b-container> -->

      <slot name="modal_below_tabs" v-bind="{ mutate, params, loading, show }"></slot>

      <!-- EXTRA STUFF -->
      <div v-if="loading === false" class="p-2 p-sm-3">
        <div v-if="_.get(class_, ['functions', 'export', 'pdf'])">
          <hr />
          <PdfView :class_="class_" :sourceParams="params" :_pdf_mode.sync="pdf_mode" :enable_auto_reload="enable_auto_reload_pdf" />

          <div v-if="class_['functions']['export']['pdf']['email_relationship']">
            <!-- TODO: PDF send button should be inside PdfView, as it controls Preview mode (or, preview mode should be controlled by the Modal such that pdf basse64 is returned too)  -->
            <b-button-toolbar class="justify-content-between">
              <div>
                <!-- <b-button-group class="mx-1">
                  <b-btn v-if="params && params['id']" variant="success" @click="request('pdf_send')">Send PDF</b-btn>
                </b-button-group> -->
              </div>
              <div>
                <b-button-group class="mx-1">
                  <span id="send_pdf_wrapper">
                    <b-btn v-if="params && params['id']" :disabled="pdf_mode !== 'view'" variant="success" @click="request('pdf_send')">Send PDF</b-btn>
                  </span>
                  <b-tooltip v-if="pdf_mode !== 'view'" target="send_pdf_wrapper">Reload Saved to Send</b-tooltip>
                </b-button-group>
              </div>
            </b-button-toolbar>
          </div>
        </div>

        <div v-if="class_['frontend'] && class_['frontend']['set']">
          <!-- TODO： temporarily disable IndexView, not a good idea -->
          <!-- <template v-if="class_['frontend']['set']['index']">
            <div v-for="(index_item, key) in class_['frontend']['set']['index']" :key="key">
              <b-card no-body>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn
                    block
                    href="#"
                    v-b-toggle="'accordion' + key"
                    variant="info"
                  >{{ _.startCase(index_item['class_name']) }}</b-btn>
                </b-card-header>
                <b-collapse :id="'accordion' + key" accordion="my-accordion">
                  <b-card-body>
                    <IndexView :index_item="index_item" :sourceParams="params" />
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </template> -->

          <div v-if="class_['frontend']['set']['pdf']">
            <div v-for="(pdf_item, key) in class_['frontend']['set']['pdf']" :key="key">
              <hr />
              <PdfView :class_="class_" :sourceParams="params" :_pdf_mode.sync="pdf_mode" :enable_auto_reload="enable_auto_reload_pdf" :pdf_item="pdf_item" />
            </div>
          </div>
        </div>

        <div v-if="class_['actions']">
          <div v-for="(action_item, key) in class_['actions']" :key="key">
            <hr />
            <ActionView :class_="class_" :action_item="action_item" :sourceParams="params" @action_success="() => (mutated = true)" />
          </div>
        </div>
      </div>
      <!-- {{params}} -->
      <slot name="modal_bottom" v-bind="{ mutate, params, loading, show }"></slot>
      <div slot="modal-footer" class="w-100">
        <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
        <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
        <div v-if="!error_message && params['_data_is_draft']" class="alert alert-warning" role="alert">Preview Success (changes not saved)</div>
        <div v-if="with_childs_mode" class="alert alert-warning" role="alert">Data loaded from table and might be outdated, please reload manually</div>
        <!-- {{is_params_changed}} -->
        <!-- {{params}} -->
        <ButtonBar
          :loading="loading"
          :allow_delete="delete_allowed"
          :allow_edit="edit_allowed && edit_button_enabled"
          :allow_copy="params && params['id'] ? true : false"
          :allow_uncopy="backup_params && backup_params['id'] ? true : false"
          :allow_reset="!manual_get && params && params['id'] && is_params_changed ? true : false"
          :allow_preview="edit_allowed && edit_button_enabled && params && is_params_changed ? true : false"
          @reset="get()"
          @preview="preview()"
          @copy="toggleCopy()"
          @uncopy="toggleCopy()"
          @delete="$refs['deleteConfirmRef'].showModal = true"
          @close="show = false"
          @add="request('create')"
          @edit="request('update')"
          :mode="mode"
          :delete_button_label="class_['timestamped'] ? 'Archive' : 'Delete'"
          :edit_button_label="edit_button_label_"
        ></ButtonBar>
      </div>
    </b-modal>

    <ConfirmationModal
      ref="deleteConfirmRef"
      @onConfirm="request('delete')"
      :title="'Confirm ' + (class_['timestamped'] ? 'Archive' : 'Delete')"
      :message="'Are you sure to ' + (class_['timestamped'] ? 'Archive' : 'Delete') + ' this item?'"
      :confirm_text="'Confirm ' + (class_['timestamped'] ? 'Archive' : 'Delete')"
    ></ConfirmationModal>
    <!-- <ConfirmationModal
      ref="copyConfirmRef"
      @onConfirm="toggleCopy()"
      title="Confirm Copy"
      message="Are you sure to Copy this item?"
      confirm_text="Confirm Copy"
    ></ConfirmationModal>-->
  </div>
</template>

<script>
import _ from 'lodash';
import PdfView from './PdfView';
import IndexView from './IndexView';
import ActionView from './ActionView';
import TabView from './components/modal/TabView';
import ButtonBar from './components/modal/ButtonBar';
import ConfirmationModal from './components/modal/ConfirmationModal';

export default {
  name: 'Modal',
  components: {
    PdfView,
    IndexView,
    ActionView,
    TabView,
    ButtonBar,
    ConfirmationModal,
  },
  props: {
    _rel_search_components: {},
    class_name: {
      type: String,
      default: null,
    },
    fieldSetting: {
      type: Object,
    },
    submitId: {
      type: String,
    },
    fixed_params: {
      //causes relationship tabs to lock changes
      type: Object,
    },
    _read_params: {
      //causes relationship tabs to lock changes
      type: Object,
    },
    _params: {
      type: Object,
    },
    _immutable: {
      type: Boolean,
      default: false,
    },
    enable_auto_reload_pdf: {
      type: Boolean,
      default: false,
    },
    _mutated: {},
    with_childs_mode: {
      //IMPORTANT TODO: this is a temporary solution, use isWithRelationshipsSatisfied instead
      type: Boolean,
      default: false,
    },
    manual_get: {},
    edit_button_label: {
      type: String,
      default: 'Edit',
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      show: false,
      params: {},
      backup_params: null,
      server_params: null,
      selected_data: [],
      loading: null, //must be true to avoid component from loading
      is_params_changed: false,
      skip_params_watcher: false,
      pdf_mode: null,

      mutated_: false,
    };
  },
  computed: {
    mode() {
      return this.params && this.params['id'] ? 'edit' : 'add';
    },
    class_() {
      return this.$d.getClass(this.class_name);
    },
    properties() {
      return this.class_ ? this.class_['properties'] : null;
    },
    relationships() {
      if (this.introspect) {
        return this.introspect.relationships;
      }
    },
    introspect() {
      return this.$d.introspect;
    },
    class_relationships() {
      return this.$d.getRelationships(this.class_['class_name'], 'to');
    },
    class_relationships_slotNames() {
      return _.map(this.class_relationships, relationship => relationship['from']['class_name'] + '_' + relationship['relationship_name']);
    },
    delete_allowed() {
      if (this._immutable) {
        return false;
      }

      if (this.class_['deletable'] == false) {
        return false;
      }

      if (!_.get(this.params, ['id'])) {
        return false;
      }

      if (this.class_['timestamped'] == true && _.get(this.params, ['archived_at'])) {
        //already archived
        return false;
      }

      return true;
    },
    edit_allowed() {
      if (this._immutable) {
        return false;
      }

      if (_.get(this.params, ['id']) && this.class_['editable'] == false) {
        return false;
      }

      return true;
    },
    edit_button_enabled() {
      if (_.get(this.class_, ['frontend', 'set', 'edit', 'disabled'], false)) {
        return false;
      }

      return true;
    },
    edit_button_label_() {
      if (this.class_['timestamped']) {
        if (_.get(this.params, ['archived_at'])) {
          return 'Restore';
        }
        return 'Update';
      }
      return this.edit_button_label;
    },
    read_params() {
      return {
        with_childs: {
          with_virtual_properties: true,
          index_withs: true,
        },
      };
    },
    mutated: {
      get: function() {
        if (typeof this._mutated != 'undefined') {
          return this._mutated;
        }

        return this.mutated_;
      },
      // setter
      set: function(newValue) {
        this.mutated_ = newValue;
        this.$emit('update:_mutated', newValue);
      },
    },
  },
  watch: {
    params: {
      deep: true,
      handler: function(new_value, old_value) {
        if (this.skip_params_watcher !== true) {
          console.log('params changed from Modal.vue');
          this.paramUpdateOrSanitize();
          this.is_params_changed = true;
          /* _.debounce(() => {
         //do something

          }, 1000)(); */

          //TODO: clear success message on edit / change (does not work propperly, causes race conditon on request())
          // this.success_message = null; //
          // delete this.params['_data_is_draft']
        } else {
          console.log('skipped params watcher');
          this.skip_params_watcher = false;
        }
      },
    },
    _params(to, from) {
      console.log('_params changed', this._params);
      this.initParams();
      console.log(this.params);
    },
    fixed_params(to, from) {
      console.log('fixed_params changed', this.fixed_params);
      this.init();
      console.log(this.params);
    },
  },
  created() {
    this._ = _;

    this.get = _.debounce(this.get_undebounced, 1000);
    this.getShortDebounce = _.debounce(this.get_undebounced, 100);

    this.init();
  },
  methods: {
    mutate(prop, val, callback) {
      this[prop] = val;
      if (callback) {
        callback();
      }
    },
    resetData() {
      //ref: https://stackoverflow.com/a/40856312/3553367
      Object.assign(this.$data, this.$options.data.call(this));
    },
    init() {
      //NOTE: if params is empty, need to initialize relations for reactivity
      this.$d.initializeParamRelations(this.params, this.class_relationships); //TODO: somehow must make it reactive first when empty
      this.initParams();
      this.$d.initializeParamRelations(this.params, this.class_relationships); //TODO: sdo it again, just to make sure the relationship's properties are initialized and reactive

      if (this.params && this.params['id']) {
        if (!this.with_childs_mode) {
          this.getShortDebounce();
        } else {
          this.loading = false; //needed to indicate that all data is loaded
        }
      }
    },
    initParams() {
      //TODO: copied from TabView
      this.params = _.merge(this.params, this._params);
      if (!this.params['id']) {
        //TODO: the items in fixed_params might be virtual but still set. This can cause misunderstanding where the data is set when save but it's not
        this.params = _.merge(this.params, this.fixed_params); //this will replace relations
        this.setParamsDefaults();
        console.log('initParams', this.params);
      }
      this.$set(this, 'params', this.params);

      /* if (!_.isEmpty(this.fixed_params)) {

        this.params = this.mergePropsAndRels(this.params, this.fixed_params); //this will concat relations
        console.log('temp_params', this.params);

        // let temp_params = this.mergePropsAndRels(_.cloneDeep(this.params), this.fixed_params); //this will concat relations
        // console.log('temp_params', this.params, temp_params);
        // this.$emit('update:params', temp_params);
      } */
    },
    paramUpdateOrSanitize() {
      this.$d.updateVirtualRelationships(this.params, this.class_relationships);
      this.skip_params_watcher = true;
    },
    setParamsDefaults() {
      //TODO: copied from TabView
      this.properties.forEach(property => {
        let property_key = property['property_key'];

        let value = undefined; //allows setting to null, if asked to

        if (_.has(property, ['default_value', 'function'])) {
          if (property['default_value']['function'] == 'now') {
            if (property['type'] == 'datetime') {
              value = this.$d.momentFormatDatetime(this.$d.momentSynced());
            }
            if (property['type'] == 'date') {
              value = this.$d.momentFormatDate(this.$d.momentSynced());
            }
            if (property['type'] == 'time') {
              value = this.$d.momentFormatTime(this.$d.momentSynced());
            }
          }
        }

        if (_.has(property, ['default_value', 'value'])) {
          value = _.get(property, ['default_value', 'value']);
        }

        if (typeof value !== 'undefined' && _.isNil(this.params[property_key])) {
          this.$set(this.params, property_key, value);
        }
      });
    },
    directSubmit(mode) {
      this.resetData();
      this.init();
      this.request(mode);
    },
    open() {
      this.resetData();
      this.init();

      this.show = true;
    },
    preview() {
      let is_preview = true;
      this.getShortDebounce(
        this.id,
        {
          _draft: this.params,
        },
        is_preview
      );
    },
    updateParam(updated_param, is_real = false) {
      let original_id = _.get(this.server_params, ['id']);
      if (!is_real) {
        this.server_params = _.omit(updated_param, ['id']); //exclude id, avoid mistracking the id
        if (original_id) {
          this.server_params['id'] = original_id;
        }
      } else {
        this.server_params = Object.assign({}, updated_param); //exclude id, avoid mistracking the id
      }
    },
    get_undebounced(id = null, extra_index_params = {}, is_preview = false) {
      this.loading = true;
      this.$NProgress.start();
      this.error_message = null;
      this.success_message = null;

      var get_from_id = id;
      if (!get_from_id) {
        get_from_id = this.params['id'];
      }
      var index_params = {
        id: get_from_id,
      };

      if (this.read_params) {
        index_params = Object.assign(index_params, this.read_params);
      }

      index_params = Object.assign(index_params, extra_index_params);

      if (this._read_params) {
        index_params = Object.assign(index_params, this._read_params);
      }

      var config = {
        method: 'post',
        url: 'descriptor/' + this.class_['class_key'] + '/read',
        data: index_params,
      };
      var cancelled = false;
      this.$api(config)
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            // this.server_params = _.assign(this.params, _.omit(data[this.class_['class_key']], ['id'])); //exclude id, avoid mistracking the id
            // this.server_params = data[this.class_['class_key']];
            if (is_preview) {
              this.updateParam(data[this.class_['class_key']], false);
            } else {
              this.server_params = Object.assign({}, data[this.class_['class_key']]);
            }

            console.log('server_params', this.server_params);
            // this.params = data[_class['class_key']];
            this.is_params_changed = false;
            this.skip_params_watcher = true;
            this.reload(this.server_params);

            // this.$forceUpdate();
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (error) {
            this.error_message = this.$api.getValidation(error);
          }

          if (this.$api.isCancel(axios_error)) {
            console.log('Request cancelled');
            cancelled = true;
          } else if (!this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          } else {
            console.error(axios_error);
          }
        })
        .then(() => {
          if (!cancelled) {
            this.$NProgress.done();
            this.loading = false;
          }
        });
    },
    request(method) {
      this.error_message = null;
      this.success_message = null;
      this.loading = true;

      var api_url = 'descriptor/' + _.snakeCase(this.class_['class_name']);
      var config = {};

      let temp_params = {};

      if (this.read_params) {
        temp_params = Object.assign(temp_params, this.read_params);
      }

      if (this._read_params) {
        temp_params = Object.assign(temp_params, this._read_params);
      }

      temp_params = Object.assign(temp_params, _.omit(this.params, ['_data_is_draft']));
      // temp_params = Object.assign(temp_params, this.params);

      if (this.class_['timestamped']) {
        //TODO: hacky way to manage updating and deleting of timestamped data
        temp_params['recorded_at'] = this.$d.momentFormat(this.$d.momentSynced());
        if (method == 'update') {
          temp_params['archived_at'] = null;
        } else if (method == 'delete') {
          temp_params['archived_at'] = temp_params['recorded_at'];
          method = 'update';
        }
      }

      var flag = true;
      if (method == 'create') {
        config = {
          method: 'post',
          url: api_url + '/create',
          data: temp_params,
        };
      } else if (method == 'copy') {
        temp_params['id'] = null;
        config = {
          method: 'post',
          url: api_url + '/create',
          data: temp_params,
        };
      } else if (method == 'update') {
        config = {
          method: 'post',
          url: api_url + '/update',
          data: temp_params,
        };
      } else if (method == 'delete') {
        config = {
          method: 'post',
          url: api_url + '/delete',
          data: temp_params,
        };
      } else if (method == 'pdf_send') {
        config = {
          method: 'post',
          url: api_url + '/pdf/send',
          data: {
            id: temp_params['id'],
          },
        };
      } else {
        //invalid mode
        flag = false;
      }

      if (flag) {
        console.log(method, temp_params);

        const submitId = this.submitId;

        this.$NProgress.start();
        this.$api(config)
          .then(response => {
            // success callback
            var responseData = this.$api.getData(response);
            if (responseData) {
              if (method == 'create') {
                this.success_message = 'Successfully Added';
                this.$emit('createSuccess', { responseData, fieldSetting: this.fieldSetting, submitId });
              } else if (method == 'update') {
                this.success_message = 'Successfully Updated';
              } else if (method == 'delete') {
                this.success_message = 'Successfully Deleted';
              } else if (method == 'pdf_send') {
                this.success_message = 'Successfully Sent';
              } else {
                this.success_message = 'Successful';
              }

              this.mutated = true;

              if (method == 'create' || method == 'update') {
                // console.log(data[this.class_['class_key']]);
                if (responseData[this.class_['class_key']]) {
                  this.updateParam(responseData[this.class_['class_key']], true);

                  console.log('server_params', this.server_params);
                  // this.params = data[_class['class_key']];
                  this.is_params_changed = false;
                  this.skip_params_watcher = true;
                  this.reload(this.server_params);
                }
              } else if (method == 'delete') {
                this.server_params['id'] = null;

                console.log('server_params', this.server_params);
                this.is_params_changed = false;
                this.skip_params_watcher = true;
                this.reload(this.server_params);
              } else if (method == 'pdf_send') {
                // TODO
              } else {
                setTimeout(() => {
                  this.show = false;
                }, 500);
              }
            }
          })
          .catch(axios_error => {
            // error callback
            var error = this.$api.getError(axios_error);
            if (error) {
              this.error_message = this.$api.getValidation(error);
            }
          })
          .then(() => {
            this.$NProgress.done();
            this.loading = false;
            if (!this.success_message && !this.error_message) {
              this.error_message = this.$api.defaultErrorMessage;
            }
            this.$emit('createFailed', { fieldSetting: this.fieldSetting, submitId: this.submitId, message: this.error_message });
          });
      }
    },
    toggleCopy() {
      if (!this.backup_params) {
        this.backup_params = this.params; //copy by reference
        this.params = _.cloneDeep(this.params); //detatch all references
        this.params['id'] = null;
        // TODO: need to remove IDs of recursive relations
        this.setParamsDefaults();
      } else {
        this.params = this.backup_params; //restore by reference
        this.backup_params = null;
      }
    },
    reload(params) {
      // this.params = params;
      this.params = Object.assign({}, params);
      // console.log('pass_form_tab_veiw',this.params)
    },
  },
};
</script>

<style scoped>
/* .card-body >>> thead > tr > th,
.card-body >>> tbody > tr > td {
  white-space: nowrap !important;
} */
</style>

<style lang="scss">
@media (min-width: 992px) {
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1000px;
  }
}

.modal-full {
  /* min-width: 95%; */
  min-width: 85%;
  /* margin: 0; */
  @media (max-width: 575px) {
    &.modal-dialog {
      margin: 0;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.3; /*orignally 0.5 */
}
</style>
