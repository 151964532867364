import InternalReceiptYearMonthData from './json/InternalReceiptYearMonthData';
import InvoiceYearMonthData from './json/InvoiceYearMonthData';
import OrderYearMonthData from './json/OrderYearMonthData';
import AccountYearMonthData from './json/AccountYearMonthData';
import CallYearMonthData from './json/CallYearMonthData';
import OrderProductYearMonthData from './json/OrderProductYearMonthData';
import OrderPackageYearMonthData from './json/OrderPackageYearMonthData';
import DriverOrderYearMonthData from './json/DriverOrderYearMonthData';
import AccountManagerAccountYearMonthData from './json/AccountManagerAccountYearMonthData';
import AccountManagerCallDoneYearMonthData from './json/AccountManagerCallDoneYearMonthData';
import DeliveryCompleteYearMonthData from './json/DeliveryCompleteYearMonthData';
import DeliveryInCompleteYearMonthData from './json/DeliveryInCompleteYearMonthData';
import DeliveryYearMonthData from './json/DeliveryYearMonthData';
import CallYearWeekData from './json/CallYearWeekData';
import CallYearMonthDayData from './json/CallYearMonthDayData';


// TODO: should move this out of coreui repo, since this is jantzen specific
const chart_configuration = {
    InternalReceiptYearMonthData,
    InvoiceYearMonthData,
    OrderYearMonthData,
    AccountYearMonthData,
    CallYearMonthData,
    OrderProductYearMonthData,
    OrderPackageYearMonthData,
    DriverOrderYearMonthData,
    AccountManagerAccountYearMonthData,
    AccountManagerCallDoneYearMonthData,
    // BillingAccountInvoiceAgingData (no chart, table view make more sense)
    DeliveryCompleteYearMonthData,
    DeliveryInCompleteYearMonthData,
    DeliveryYearMonthData,
    CallYearWeekData,
    CallYearMonthDayData,
};

export default chart_configuration;
