<script>
import chart_theme_configs from './chartConfiguration/chartThemeConfigs';
import { Line } from 'vue-chartjs';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { dataChecking } from '../../globalUtils';
import chart_configuration from './chartConfiguration';

const default_chart_options = {
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
    custom: CustomTooltips,
    intersect: true,
    mode: 'index',
    position: 'nearest',
    callbacks: {
      labelColor: function (tooltipItem, chart) {
        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
      }
    }
  },
  elements: {
    line: {
      tension: 0,
    },
    point: {
      // radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  },
};

const setDataSet = ({ dataSet, scope }) => {
  scope.dataSet = dataSet;
  scope.renderContent({
    dataSet,
  });

  if (scope.cache_key) {
    // console.log('save pagination', scope.cache_key);
    scope.$d.setCache(this.cache_key);
  }

  scope.loadingDone = true;
  scope.$forceUpdate();
};

const generateChartParams = ({ scope }) => {
  const chartConfig = chart_configuration[scope.class_name];
  let haveError = false;
  [
    {
      title: 'class_name',
      data: scope.class_name,
    },
    {
      title: 'chart_options',
      data: chartConfig.chart_options,
    },
    {
      title: 'x_config',
      data: chartConfig.x_config,
    },
    {
      title: 'y_config',
      data: chartConfig.y_config,
    },
  ].forEach((config) => {
    if (!config.data) {
      haveError = true;
      console.error(`${config.title} not available.`);
    }
  });

  if (haveError) {
    return;
  }

  const x_axes = [];
  if (chartConfig.chart_options.x_axes && chartConfig.chart_options.x_axes.length) {
    chartConfig.chart_options.x_axes.forEach((xAxisConfig) => {
      x_axes.push({
        ...xAxisConfig,
      });
    });
  }

  const y_axes = [];
  if (chartConfig.chart_options.y_axes && chartConfig.chart_options.y_axes.length) {
    chartConfig.chart_options.y_axes.forEach((yAxisConfig, index) => {
      y_axes.push({
        id: yAxisConfig.id,
        position: (index ? 'right' : 'left'),
        // ticks: { beginAtZero: true },
        ...yAxisConfig,
      });
    });
  }

  const x_config = chartConfig.x_config;
  const target_property = scope.property_data.find((property) => property.property_key === x_config.property_key);
  x_config.type = target_property.type;
  x_config.title = target_property.property_name;

  const y_config = [];
  if (chartConfig.y_config && chartConfig.y_config.length) {
    chartConfig.y_config.forEach((yConfigItem, index) => {
      const target_property = scope.property_data.find((property) => property.property_key === yConfigItem.property_key);
      y_config.push({
        property_key: target_property.property_key,
        dataType: target_property.type,
        chart_setting: {
          borderColor: chart_theme_configs[index].borderColor,
          backgroundColor: chart_theme_configs[index].backgroundColor,
          pointHoverBackgroundColor: chart_theme_configs[index].pointHoverBackgroundColor,
          borderWidth: 2,
          label: target_property.property_name,
          yAxisID: yConfigItem.y_axis_ID,
          type: yConfigItem.type,
        },
      });
    });
  }

  return {
    chart_options: { scales: { xAxes: x_axes, yAxes: y_axes } },
    x_config,
    y_config,
  };
}

export default {
  extends: Line,
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    class_name: {
      type: String,
    },
    data: {
      type: Array,
    },
    chart_configurations: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newData) {
        setDataSet({ dataSet: newData, scope: this });
      },
      immediate: true,
    },
  },
  data: () => {
    return {
      dataSet: null,
      loadingDone: false,
    };
  },
  computed: {
    property_data() {
      return this.$d.getProperties(this.class_name);
    },
    // classData() {
    //   return this.$d.getClass(this.class_name);
    // },
  },
  created () {
    if (this.data && this.data) {
      setTimeout(() => {
        setDataSet({ dataSet: this.data, scope: this });
      }, 0);
    } else if (this.class_name) {
      this.$d.request(
        'index',
        this.class_name,
        {
          index_withs: true,
        },
        (dataSet) => { setDataSet({ dataSet, scope: this }); },
        (error_message) => {
          console.error(error_message);
        }
      );
    } else {
      console.error('data or class_name for chart not found.');
      return;
    }
  },
  methods: {
    renderContent({ dataSet }) {
      const chart_params = this.chart_configurations || generateChartParams({ scope: this });

      // start convert data into chartJs format
      const x_axis_data = [];
      const y_axis_data = {};
      chart_params.y_config.forEach((y_item) => {
        y_axis_data[y_item.property_key] = [];
      });

      dataSet.forEach((row) => {
        x_axis_data.push(dataChecking(row, chart_params.x_config.property_key));

        chart_params.y_config.forEach((y_item) => {
          const value = dataChecking(row, y_item.property_key);
          const parsed_value = y_item.dataType === 'integer' ? parseInt(value) : (y_item.dataType === 'number' ? parseFloat(value) : value);
          y_axis_data[y_item.property_key].push(parsed_value);
        });
      });

      this.renderChart(
        {
          labels: x_axis_data,
          datasets: chart_params.y_config.map((y_item) => (
            {
              ...y_item.chart_setting,
              data: y_axis_data[y_item.property_key],
            }
          )),
        },
        {
          ...default_chart_options,
          ...chart_params.chart_options,
        },
      );
    },
  },
}
</script>
