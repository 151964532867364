import { render, staticRenderFns } from "./DropdownsView.vue?vue&type=template&id=6b228dea&scoped=true"
import script from "./DropdownsView.vue?vue&type=script&lang=js"
export * from "./DropdownsView.vue?vue&type=script&lang=js"
import style0 from "./DropdownsView.vue?vue&type=style&index=0&id=6b228dea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b228dea",
  null
  
)

export default component.exports