const chartThemeConfigs = [
  {
    borderColor: 'RGB(255, 0, 41)',
    backgroundColor: 'RGBA(255, 0, 41, 0.1)',
    pointHoverBackgroundColor: 'RGBA(255, 0, 41, 0.95)',
  },
  {
    borderColor: 'RGB(55, 126, 184)',
    backgroundColor: 'RGBA(55, 126, 184, 0.1)',
    pointHoverBackgroundColor: 'RGBA(55, 126, 184, 0.95)',
  },
  {
    borderColor: 'RGB(102, 166, 30)',
    backgroundColor: 'RGBA(102, 166, 30, 0.1)',
    pointHoverBackgroundColor: 'RGBA(102, 166, 30, 0.95)',
  },
  {
    borderColor: 'RGBA(0, 210, 213, 10)',
    backgroundColor: 'RGBA(0, 210, 213, 0.1)',
    pointHoverBackgroundColor: 'RGBA(0, 210, 213, 0.95)',
  },
  {
    borderColor: 'RGB(255, 127, 0)',
    backgroundColor: 'RGBA(255, 127, 0, 0.1)',
    pointHoverBackgroundColor: 'RGBA(255, 127, 0, 0.95)',
  },
  {
    borderColor: 'RGB(175, 141, 0)',
    backgroundColor: 'RGBA(175, 141, 0, 0.1)',
    pointHoverBackgroundColor: 'RGBA(175, 141, 0, 0.95)',
  },
  {
    borderColor: 'RGB(127, 128, 205)',
    backgroundColor: 'RGBA(127, 128, 205, 0.1)',
    pointHoverBackgroundColor: 'RGBA(127, 128, 205, 0.95)',
  },
  {
    borderColor: 'RGB(179, 233, 0)',
    backgroundColor: 'RGBA(179, 233, 0, 0.1)',
    pointHoverBackgroundColor: 'RGBA(179, 233, 0, 0.95)',
  },
  {
    borderColor: 'RGB(196, 46, 96)',
    backgroundColor: 'RGBA(196, 46, 96, 0.1)',
    pointHoverBackgroundColor: 'RGBA(196, 46, 96, 0.95)',
  },
  {
    borderColor: 'RGB(166, 86, 40)',
    backgroundColor: 'RGBA(166, 86, 40, 0.1)',
    pointHoverBackgroundColor: 'RGBA(166, 86, 40, 0.95)',
  },
  {
    borderColor: 'RGB(247, 129, 191)',
    backgroundColor: 'RGBA(247, 129, 191, 0.1)',
    pointHoverBackgroundColor: 'RGBA(247, 129, 191, 0.95)',
  },
  {
    borderColor: 'RGB(141, 211, 199)',
    backgroundColor: 'RGBA(141, 211, 199, 0.1)',
    pointHoverBackgroundColor: 'RGBA(141, 211, 199, 0.95)',
  },
  {
    borderColor: 'RGB(190, 186, 218)',
    backgroundColor: 'RGBA(190, 186, 218, 0.1)',
    pointHoverBackgroundColor: 'RGBA(190, 186, 218, 0.95)',
  },
  {
    borderColor: 'RGB(251, 128, 114)',
    backgroundColor: 'RGBA(251, 128, 114, 0.1)',
    pointHoverBackgroundColor: 'RGBA(251, 128, 114, 0.95)',
  },
  {
    borderColor: 'RGB(128, 177, 211)',
    backgroundColor: 'RGBA(128, 177, 211, 0.1)',
    pointHoverBackgroundColor: 'RGBA(128, 177, 211, 0.95)',
  },
  {
    borderColor: 'RGB(253, 180, 98)',
    backgroundColor: 'RGBA(253, 180, 98, 0.1)',
    pointHoverBackgroundColor: 'RGBA(253, 180, 98, 0.95)',
  },
  {
    borderColor: 'RGB(252, 205, 229)',
    backgroundColor: 'RGBA(252, 205, 229, 0.1)',
    pointHoverBackgroundColor: 'RGBA(252, 205, 229, 0.95)',
  },
  {
    borderColor: 'RGB(188, 128, 189)',
    backgroundColor: 'RGBA(188, 128, 189, 0.1)',
    pointHoverBackgroundColor: 'RGBA(188, 128, 189, 0.95)',
  },
  {
    borderColor: 'RGB(152, 78, 163)',
    backgroundColor: 'RGBA(152, 78, 163, 0.1)',
    pointHoverBackgroundColor: 'RGBA(152, 78, 163, 0.95)',
  },
  {
    borderColor: 'RGB(255, 237, 111)',
    backgroundColor: 'RGBA(255, 237, 111, 0.1)',
    pointHoverBackgroundColor: 'RGBA(255, 237, 111, 0.95)',
  },
];

export default chartThemeConfigs;