export const dataChecking = (object, ...argsArr) => {
  if (!argsArr || !argsArr.length) {
    return object;
  }

  const args = argsArr[0] && argsArr[0].constructor === Array ? argsArr[0] : argsArr;
  let obj = object;

  for (let i = 0; i < args.length; i += 1) {
    if (Array.isArray(obj) && !obj[args[i]]) {
      return null;
    } else if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return null;
    }
    obj = obj[args[i]];
  }
  return obj;
};

export const updateDataByPath = (data, value, ...argsArr) => {
  let args = argsArr;
  if (argsArr[0].constructor === Array) {
    args = argsArr[0];
  }

  let target = data;
  for (let i = 0; i < args.length; i++) {
    if (i === args.length - 1) {
      target[args[i]] = value;
    } else if (data[args[i]]) {
      target = data[args[i]];
    } else {
      target[args[i]] = {};
      target = target[args[i]];
    }
  }
};

export const setDataByPath = (data, ...argsArr) => {
  let args = argsArr;
  if (argsArr[0].constructor === Array) {
    args = argsArr[0];
  }

  let obj = data;
  for (let i = args.length - 1; i >= 0; i--) {
    obj = {
      [args[i]]: obj,
    };
  }

  return obj;
};

export const exportToCsv = (filename, rows) => {
  const processRow = row => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }

      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const renderDateTimeFormat = (dateObject, options = {}) => {
  if (!dateObject) {
    return <span className="text-lightgray">- empty -</span>;
  }

  const params = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12',
  };

  if (options.showSeconds) {
    params.second = '2-digit';
  }

  if (options.Milliseconds) {
    params.fractionalSecondDigits = 3;
  }

  return dateObject.getTime() === dateObject.getTime() ? dateObject.toLocaleString('en-MY', params) : 'Invalid Date';
};
