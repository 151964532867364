const InternalReceiptYearMonthData = {
  chart_options: {
    x_axes:  [
      {
        // gridLines: { drawOnChartArea: false },
      }
    ],
    y_axes: [
      { id: 'RM' },
      { id: 'Count' },
    ],
  },
  x_config: {
    property_key: 'id',
  },
  y_config: [
    {
      property_key: 'sum_total_amount',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'sum_total_matched',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'sum_over_matched',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'sum_under_matched',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'count',
      y_axis_ID: 'Count',
    },
  ],
};

export default InternalReceiptYearMonthData;
