const AccountManagerCallDoneYearMonthData = {
  chart_options: {
    x_axes:  [
      {
        // gridLines: { drawOnChartArea: false },
      }
    ],
    y_axes: [
      { id: 'Count' },
    ],
  },
  x_config: {
    property_key: 'id',
  },
  y_config: [
    {
      property_key: '_total_call',
      y_axis_ID: 'Count',
    },
  ],
};

export default AccountManagerCallDoneYearMonthData;
