<template>
  <div>
    <PropertiesView
      :properties="properties"
      :params.sync="params"
      :disabled="disabled"
      :with_virtual="with_virtual_properties_ ? true : false"
      :only_virtual_properties="with_virtual_properties_"
    ></PropertiesView>
    <DropdownsView
      @reload="
        params => {
          reload(params);
        }
      "
      :fixed_params="fixed_params"
      :relationships="relationships"
      :params.sync="params"
      :disabled="disabled"
    ></DropdownsView>
    <b-card v-if="show_virtual_properties_ && (params['id'] ? true : false)">
      <PropertiesView
        :properties="properties"
        :params.sync="params"
        :disabled="disabled"
        :is_virtual="true"
        :only_virtual_properties="show_virtual_properties_"
      ></PropertiesView>
    </b-card>
    <RelationshipsTabs
      v-if="relationships && relationships.length > 0"
      :_rel_search_components="_rel_search_components"
      :fixed_params="fixed_params"
      :relationships="relationships"
      :params.sync="params"
      :disabled="disabled"
      :allow_mysql_view="allow_mysql_view"
      :allow_columns="allow_columns"
      :find_button_visibility="find_button_visibility"
    >
      <template
        v-for="slotName in _.map(this.relationships, relationship => relationship['from']['class_name'] + '_' + relationship['relationship_name'])"
        v-slot:[slotName]="{ relationship, params, disabled }"
      >
        <!-- {{relationship['relationship_name']}} -->
        <slot :name="slotName" :relationship="relationship" :params.sync="params" :disabled="disabled"></slot>
      </template>
    </RelationshipsTabs>
  </div>
</template>
<script>
import _ from 'lodash';
import PropertiesView from './PropertiesView';
import DropdownsView from './DropdownsView';
import RelationshipsTabs from './RelationshipsTabs';

export default {
  name: 'TabView',
  components: {
    PropertiesView,
    DropdownsView,
    RelationshipsTabs,
  },
  props: {
    _rel_search_components: {},
    class_name: {
      type: String,
    },
    fixed_params: {
      type: Object,
    },
    params: {
      type: Object,
      required: true,
    },
    only_relationship_names: {
      //pass empty array to totally remove relationships
      type: Array,
    },
    only_property_keys: {
      //pass empty array to totally remove properties
      type: Array,
    },
    only_dropdown_relationships: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allow_mysql_view: {
      type: Boolean,
      default: true,
    },
    allow_columns: {
      type: Boolean,
      default: true,
    },
    with_virtual_properties: {
      default: null,
    },
    show_virtual_properties: {
      default: null,
    },
    find_button_visibility: {
      type: Object,
    },
  },
  data: () => {
    return {};
  },
  mounted() {},
  computed: {
    class_() {
      return this.$d.getClass(this.class_name);
    },
    properties() {
      let properties = this.class_['properties'];
      if (!_.isNil(this.only_property_keys)) {
        properties = _.filter(properties, property => {
          return this.only_property_keys.includes(property['property_key']);
        });
      }
      return properties;
    },
    relationships() {
      let relationships = this.$d.getRelationships(this.class_name, 'to');

      if (!_.isNil(this.only_relationship_names)) {
        relationships = _.filter(relationships, relationship => {
          return this.only_relationship_names.includes(relationship['relationship_name']);
        });
      }

      if (this.only_dropdown_relationships) {
        relationships = _.filter(relationships, relationship => {
          if (_.get(relationship, ['flags', 'dropdown']) == true) {
            return true;
          }

          if (_.get(relationship, ['to', 'frontend', 'mode']) == 'dropdown') {
            return true;
          }

          return false;
        });
      }
      return relationships;
    },
    introspect() {
      return this.$d.introspect;
    },
    with_virtual_properties_() {
      if (!_.isNil(this.with_virtual_properties)) {
        return this.with_virtual_properties;
      }
      return _.get(this.class_, ['frontend', 'set', 'with_virtual_properties']) == true;
    },
    show_virtual_properties_() {
      if (!_.isNil(this.show_virtual_properties)) {
        return this.show_virtual_properties;
      }
      return _.get(this.class_, ['frontend', 'set', 'show_virtual_properties']) == true;
    },
  },
  watch: {
    // whenever question changes, this function will run
    'params.time_taken': function(to, from) {
      console.log('time_taken changed:', from, to);
      this.debouncedGetIsoline();
    },
    'params.center_point': function(to, from) {
      let has_changed = !_.isEqual(to, from);

      if (has_changed) {
        console.log('center_point changed:', from, to);
        this.debouncedGetIsoline();
      }
    },
    fixed_params(to, from) {
      console.log('fixed_params changed', this.fixed_params);
      this.init();
      console.log(this.params);
    },
    params(to, from) {
      if (!_.isEqual(to, from)) {
        console.log('params changed in TabView.vue', to, from);
        this.init(); //makes sure the this.fixed_params is included
      }
    },
    /* params: {
      deep: true,
      handler: function(to, from) {
        console.log('params changed from TabView.vue');
        if (!_.isEqual(to, from)) {
          this.init();
        }
      }
    } */
  },
  created() {
    this._ = _;
    this.debouncedGetIsoline = _.debounce(this.getIsoline, 500);
    console.log('fixed_params', this.fixed_params);

    this.init();
  },
  methods: {
    init() {
      this.$d.initializeParamRelations(this.params, this.relationships); //TODO: somehow must make it reactive first when empty
      this.initParams();
      this.$d.initializeParamRelations(this.params, this.relationships); //TODO: sdo it again, just to make sure the relationship's properties are initialized and reactive
    },
    initParams() {
      if (!this.params['id']) {
        this.params = _.merge(this.params, this.fixed_params); //this will replace relations
        this.setParamsDefaults();
        console.log('initParams', this.params);
      }

      /* if (!_.isEmpty(this.fixed_params)) {

        this.params = this.mergePropsAndRels(this.params, this.fixed_params); //this will concat relations
        console.log('temp_params', this.params);

        // let temp_params = this.mergePropsAndRels(_.cloneDeep(this.params), this.fixed_params); //this will concat relations
        // console.log('temp_params', this.params, temp_params);
        // this.$emit('update:params', temp_params);
      } */
    },
    setParamsDefaults() {
      //TODO: may very much need to move into PropertiesView or TabView
      this.properties.forEach(property => {
        let property_key = property['property_key'];

        let value = undefined; //allows setting to null, if asked to

        if (_.has(property, ['default_value', 'function'])) {
          if (property['default_value']['function'] == 'now') {
            if (property['type'] == 'datetime') {
              value = this.$d.momentFormatDatetime(this.$d.momentSynced());
            }
            if (property['type'] == 'date') {
              value = this.$d.momentFormatDate(this.$d.momentSynced());
            }
            if (property['type'] == 'time') {
              value = this.$d.momentFormatTime(this.$d.momentSynced());
            }
          }
        }

        if (_.has(property, ['default_value', 'value'])) {
          value = _.get(property, ['default_value', 'value']);
        }

        if (typeof value !== 'undefined' && _.isNil(this.params[property_key])) {
          this.$set(this.params, property_key, value);
        }
      });
    },
    mergePropsAndRels(to_params, from_params) {
      //TODO: very similar to extract() in EditableCard
      // to_params = Object.assign({}, from_params); //only copies at the surface

      // to_params = {};

      // to_params['id'] = _.cloneDeep(from_params['id']); //required to ensure the behaviour is similar
      // this.$set(to_params, 'id', _.cloneDeep(from_params['id']));

      //TODO: need to find a good way to explicitly define to get properties, or relationship only (what about dropdowns??)

      if (this.properties) {
        this.properties.forEach(property => {
          let property_key = property['property_key'];
          if (!_.isNil(from_params[property_key])) {
            to_params[property_key] = _.cloneDeep(from_params[property_key]);
            // this.$set(to_params, property_key, _.cloneDeep(from_params[property_key]));
          }
        });
      }

      if (this.relationships) {
        this.relationships.forEach(relationship => {
          let relation_alias = this.$d.getRelationAlias(relationship);
          if (from_params[relation_alias] && Array.isArray(from_params[relation_alias])) {
            let from_params_relations = _.cloneDeep(from_params[relation_alias]);
            if (Array.isArray(to_params[relation_alias])) {
              from_params_relations.forEach(relation => {
                //maintain reactivity?
                to_params[relation_alias].push(relation);
              });
              // to_params[relation_alias] = to_params[relation_alias].concat(from_params_relations); //copy and concat array
            } else {
              to_params[relation_alias] = from_params_relations; //replace to array
            }
            // this.$set(to_params, relation_alias, to_params[relation_alias].concat(from_params_relations));
          }
        });
      }

      // to_params = _.merge({}, from_params);

      return to_params;
    },
    reload(params) {
      // this.params = params;
      console.log('reload from TabView');
      this.$emit('reload', params);
    },
    getIsoline() {
      if (this.params['time_taken'] && this.params['center_point']) {
        this.$NProgress.start();

        this.loading_message = 'Loading Isoline...';
        this.error_message = null;
        this.success_message = null;

        var apiUrl = 'here/isoline';

        console.log(this.params['center_point']);
        console.log(this.params['center_point']['coordinates']);

        var center_point_lat = this.params['center_point']['coordinates'][1];
        var center_point_lng = this.params['center_point']['coordinates'][0];
        var time_taken = this.params['time_taken'];

        var isoline_params = {
          mode: 'fastest;car;traffic:disabled',
          // mode: 'fastest;car;traffic:enabled',
          start: 'geo!' + center_point_lat + ',' + center_point_lng,
          rangetype: 'time',
          range: time_taken,
          resolution: 1,
          //jsoncallback: isolineCallback,
          //requestId: anyUniqueRequestID,
        };
        var config = {
          method: 'get',
          url: apiUrl,
          params: isoline_params,
        };

        this.$api(config)
          .then(response => {
            // success callback
            var data = response.data;
            if (data) {
              console.log('data', data);
              this.processIsolineArray(data['response']['isoline'][0]['component'][0]['shape']);
              this.success_message = 'Isoline Polygon Generated';
              // this.$forceUpdate();
            }
          })
          .catch(axios_error => {
            // error callback
            var error = this.$api.getError(axios_error);
            console.error(axios_error);
          })
          .then(() => {
            if (!this.success_message) {
              this.error_message = 'Failed to retrieve Isoline, try again or contact Sysadmin';
            }
            this.loading_message = null;
            this.$NProgress.done();
          });
      }
    },
    processIsolineArray(isoline_array_coor_strs) {
      var geojson_point_object = {
        type: 'Polygon',
        coordinates: [[]],
      };

      isoline_array_coor_strs.forEach(function(isoline_array_coor_str) {
        var lat_lng_array = isoline_array_coor_str.split(',');

        var lat = Number(lat_lng_array[0]);
        var lng = Number(lat_lng_array[1]);
        // console.log(lat, lng);

        geojson_point_object.coordinates[0].push([lng, lat]);
      });

      this.params['polygon'] = geojson_point_object;
    },
  },
};
</script>
