<template>
  <b-row>
    <b-col cols="12">
      <transition name="slide">
        <b-card :header="title_" header-class="p-2 p-sm-3" body-class="p-2 p-sm-3">
          <div slot="header">
            {{ title_ }}
            <div class="card-header-actions">
              <b-button-group size="sm">
                <b-button v-if="class_name != null && enable_add_" @click="addButtonClicked()" variant="secondary">
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-button-group>
            </div>
          </div>

          <div class="my-2" v-if="chart_configuration[class_name]">
            <b-button
              :class="show_chart ? null : 'collapsed'"
              :aria-expanded="show_chart ? 'true' : 'false'"
              aria-controls="collapse-4"
              @click="show_chart = !show_chart"
            >{{ show_chart ? 'Hide Chart' : 'Show Chart' }}</b-button>
            <b-collapse id="collapse-4" v-model="show_chart" class="mt-3">
              <b-card v-if="show_chart">
                <CustomChart
                  :key="class_name"
                  height="300"
                  width="100%"
                  class="p-3"
                  :class_name="class_name"
                  :data="pagination.data"
                />
              </b-card>
            </b-collapse>
          </div>

          <slot name="above_search_component"></slot>

          <component
            :is="search_component"
            ref="searchRef"
            :small="small"
            @rowClicked="rowClicked"
            :row_clickable="true"
            :default_per_page="default_per_page"
            :_default_properties_sort="_default_properties_sort"
            :stateName="_.snakeCase(class_name) + '_pagination' + (stateIdentifier ? '_' + stateIdentifier : '')"
            :fields="use_fields"
            :class_name="class_name"
            :_index_params="_index_params"
            :enable_map_default="false"
            :enable_excel_download="true"
            :allow_relationships_filter="allow_relationships_filter"
            :allow_properties_filter="allow_properties_filter"
            :allow_json_filter="allow_json_filter"
            :allow_predefined_filter="allow_predefined_filter"
            :allow_select_fields="allow_select_fields"
            :_predefined_filters="_predefined_filters"
            :filter_defaults="filter_defaults"
            :default_predefined_filter_names="default_predefined_filter_names"
            :_property_cell_variants="_property_cell_variants"
            @update:pagination="
              new_pagination => {
                pagination = new_pagination;
                this.$emit('done_reload');
              }
            "
            :manual_get="manual_get"
            :with_childs_mode="with_childs_mode"
            :with_relationships="with_relationships"
            :select_propeties_even_with_property_tags="select_propeties_even_with_property_tags"
          >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
              <slot v-if="use_fields.find(use_field => use_field._slot_name == slot)" :name="slot" v-bind="scope" />
              <!-- pass through slots that are only defined in use_fields for table -->
            </template>

            <template v-slot:batch_select_column_slot="row">
              <b-button-toolbar class="table_actions" :class="$mq == 'xs' ? 'float-right' : 'float-left'">
                <b-button-group size="sm">
                  <template v-if="!(filtered_selected_items && filtered_selected_items.find(selected_item => selected_item.id == row.item.id) ? true : false)">
                    <b-button @click="addItem(row.item)" variant="outline-secondary">
                      <i class="fa fa-check"></i>
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      :id="'batch_select_button_' + row.item.id"
                      :variant="batch_request_response[row.item.id] ? (batch_request_response[row.item.id]['error_message'] ? 'danger' : 'success') : 'dark'"
                      @click="removeItem(row.item)"
                    >
                      <i class="fa fa-check"></i>
                    </b-button>
                    <b-popover
                      v-if="batch_request_response[row.item.id] && batch_request_response[row.item.id]['error_message']"
                      :target="'batch_select_button_' + row.item.id"
                      triggers="hover focus"
                      variant="danger"
                    >
                      <!-- <template v-slot:title>Error</template> -->
                      <span v-html="batch_request_response[row.item.id]['error_message']"></span>
                    </b-popover>
                    <!-- <b-tooltip
                      :target="'batch_select_button_' + row.item.id"
                      variant="danger"
                    >dasdadsdsds</b-tooltip>-->
                    <!-- <b-tooltip
                      v-if="batch_request_response[row.item.id] &&  batch_request_response[row.item.id]['error_message']"
                      :target="'batch_select_button_' + row.item.id"
                      variant="danger"
                      v-html="batch_request_response[row.item.id]['error_message']"
                    ></b-tooltip>-->
                  </template>
                </b-button-group>
              </b-button-toolbar>
            </template>
            <template v-slot:id_column_slot="row">{{ row.item.id }}</template>

            <template v-slot:bottom_button_row_slot="bottom_button_row_slot_props">
              <br />
              <b-row>
                <b-col>
                  <div
                    v-if="batch_request_success_message && batch_request_success_message != ''"
                    v-html="batch_request_success_message"
                    class="alert alert-success"
                    role="alert"
                  ></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    v-if="batch_request_error_message && batch_request_error_message != ''"
                    v-html="batch_request_error_message"
                    class="alert alert-danger"
                    role="alert"
                  ></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button-group class="mx-1">
                    <b-button variant="success" @click="addAll()">Select ALL</b-button>
                  </b-button-group>
                  <b-button-group class="mx-1">
                    <b-button variant="warning" @click="removeAll()">Unselect ALL</b-button>
                  </b-button-group>
                  <b-button-group class="mx-1">
                    <b-button variant="outline-dark" @click="get()">Reload</b-button>
                  </b-button-group>
                  <b-button-group
                    v-if="!_immutable"
                    class="mx-1"
                    :disabled="batch_request_loading || filtered_selected_items.length <= 0"
                    @click="$refs['batchDeleteConfirmRef'].showModal = true"
                  >
                    <b-button :disabled="batch_request_loading" variant="outline-danger">
                      Delete ({{ filtered_selected_items.length }})
                      <span v-if="batch_request_loading">
                        <!-- <q-spinner color="primary" size="17px" :thickness="2" /> -->
                        <b-spinner small variant="danger" label="..."></b-spinner>
                      </span>
                    </b-button>
                  </b-button-group>
                  <b-button-group
                    v-if="_.get(class_, ['functions', 'export', 'pdf', 'email_relationship'])"
                    class="mx-1"
                    :disabled="batch_request_loading || filtered_selected_items.length <= 0"
                    @click="$refs['batchEmailConfirmRef'].showModal = true"
                  >
                    <b-button :disabled="false" variant="outline-success">
                      Email ({{ filtered_selected_items.length }})
                      <span v-if="batch_request_loading">
                        <!-- <q-spinner color="primary" size="17px" :thickness="2" /> -->
                        <b-spinner small variant="success" label="..."></b-spinner>
                      </span>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
            
            <template
              v-for="relationship_actions_field in relationship_actions_fields"
              :slot="relationship_actions_field['_slot_name']"
              slot-scope="row"
            >
              <div :key="relationship_actions_field.key">
                <b-button-group size="sm">
                  <!-- <b-button
                    @click.stop="relationshipAction('create', row.item, relationship_actions_field.relationship)"
                    variant="success"
                  >
                    <i class="fa fa-plus"></i>
                  </b-button>
                  <b-button
                    @click.stop="relationshipAction('index', row.item, relationship_actions_field.relationship)"
                    variant="secondary"
                  >
                    <i class="fas fa-list-ul"></i>
                  </b-button>-->
                  <router-link :to=" getRelationshipActionRouter('view', row.item, relationship_actions_field['relationship'], relationship_actions_field['direction'])">
                    <b-button
                      variant="secondary"
                    >
                      <div><i class="fas" :class="{'fa-chevron-up': relationship_actions_field['direction'] == 'from', 'fa-chevron-down': relationship_actions_field['direction'] == 'to'}"></i> View {{relationship_actions_field['relationship'][relationship_actions_field['direction']]['context_name'] || relationship_actions_field['relationship'][relationship_actions_field['direction']]['class_name']}}</div>
                    </b-button>
                  </router-link>
                </b-button-group>
              </div>
            </template>
          </component>

          <ConfirmationModal
            ref="batchDeleteConfirmRef"
            @onConfirm="batchRequestSelected('delete')"
            :title="'Confirm ' + (class_['timestamped'] ? 'Archive' : 'Delete')"
            :message="
              `Are you sure to ${class_['timestamped'] ? 'Archive' : 'Delete'} ${filtered_selected_items.length} ${pluralize(
                'item',
                filtered_selected_items.length
              )}?`
            "
            :confirm_text="'Confirm ' + (class_['timestamped'] ? 'Archive' : 'Delete')"
          ></ConfirmationModal>

          <ConfirmationModal
            ref="batchEmailConfirmRef"
            @onConfirm="batchRequestSelected('email')"
            :title="'Confirm Email'"
            :message="`Are you sure to Email ${filtered_selected_items.length} ${pluralize('item', filtered_selected_items.length)}?`"
            :confirm_text="'Confirm Send'"
          ></ConfirmationModal>

          <slot name="modal" :_params="modal_params" :onModalClose="onModalClose">
            <component
              :is="modal_component"
              :_rel_search_components="_modal_rel_search_components"
              @close="onModalClose"
              ref="modalRef"
              v-if="enable_modal && is_modal_open"
              :class_name="class_name"
              :fixed_params="fixed_params"
              :_params="modal_params"
              :_read_params="_read_params"
              :_immutable="_immutable"
              :with_childs_mode="with_childs_mode"
              :manual_get="manual_get"
              :edit_button_label="edit_button_label"
            >
              <template v-for="slotName in class_relationships_slotNames" v-slot:[slotName]="{ relationship, params, disabled }">
                <!-- {{relationship['relationship_name']}} -->
                <slot :name="slotName" :relationship="relationship" :params.sync="params" :disabled="disabled"></slot>
              </template>
              <template v-slot:modal_above_tabs="modal_above_tabs_props">
                <!-- {{modal_above_tabs_props.loading}} -->
                <slot name="modal_above_tabs" v-bind="modal_above_tabs_props"></slot>
              </template>
              <template v-slot:modal_below_tabs="modal_below_tabs_props">
                <!-- {{modal_below_tabs_props.loading}} -->
                <slot name="modal_below_tabs" v-bind="modal_below_tabs_props"></slot>
              </template>
              <template v-slot:modal_bottom="modal_bottom_props">
                <!-- {{modal_bottom_props.loading}} -->
                <slot name="modal_bottom" v-bind="modal_bottom_props"></slot>
              </template>
              <template v-slot:modal_view="modal_view_props">
                <!-- {{modal_view.loading}} -->
                <slot name="modal_view" v-bind="modal_view_props"></slot>
              </template>
            </component>
          </slot>
        </b-card>
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import chart_configuration from './components/chart/chartConfiguration';
import CustomChart from './components/chart/CustomChart.vue';
import Modal from './Modal';
import Search from './Search';

import _ from 'lodash';
import pluralize from 'pluralize';

import ConfirmationModal from './components/modal/ConfirmationModal';

export default {
  components: {
    ConfirmationModal,
    CustomChart,
  },
  props: {
    class_name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    default_per_page: {
      default: 25,
    },
    _default_properties_sort: {
      type: Array,
    },
    show_relationship_actions: {
      type: Boolean,
      default: true,
    },
    fixed_params: {
      type: Object,
      default: null,
    },
    init_params: {
      type: Object,
    },
    allow_relationships_filter: {
      type: Boolean,
      default: true,
    },
    allow_properties_filter: {
      type: Boolean,
      default: true,
    },
    allow_json_filter: {
      type: Boolean,
      default: true,
    },
    allow_predefined_filter: {
      type: Boolean,
      default: true,
    },
    allow_select_fields: {
      type: Boolean,
      default: true,
    },
    _predefined_filters: {
      type: Array,
    },
    filter_defaults: {},
    default_predefined_filter_names: {},
    _property_cell_variants: {
      type: Array,
    },
    enable_modal: {
      type: Boolean,
      default: true,
    },
    enable_add_modal: {
      type: Boolean,
      default: true,
    },
    enable_edit_modal: {
      type: Boolean,
      default: true,
    },
    enable_add: {
      type: Boolean,
      default: null,
    },
    reload_on_close: {
      type: Boolean,
      default: true,
    },
    fields: {
      // type: Object,
      default: null,
    },
    _index_params: {
      type: Object,
    },
    _read_params: {
      type: Object,
    },
    stateIdentifier: {
      type: String,
      default: '',
    },
    _immutable: {
      type: Boolean,
      default: false,
    },
    _modal_component: {},
    _search_component: {},
    _modal_rel_search_components: {},
    with_childs_mode: {
      //IMPORTANT TODO: this is a temporary solution, use isWithRelationshipsSatisfied instead
      type: Boolean,
      default: false,
    },
    with_relationships: {
      type: Array,
    },
    manual_get: {},
    edit_button_label: {
      type: String,
      default: 'Edit',
    },
    select_propeties_even_with_property_tags: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    class_name: function() { // watch it
      this.show_chart = false;
    }
  },
  data: () => {
    return {
      show_chart: false,
      modalMode: null,
      params: {},
      is_modal_open: false,
      modal_params: null,
      selected_items: [],
      batch_request_error_message: null,
      batch_request_success_message: null,
      batch_request_response: {},
      batch_request_loading: false,
      pagination: null,
      chart_configuration,
    };
  },
  computed: {
    title_() {
      if (this.title) {
        return this.title;
      }
      return this.class_name;
    },
    introspect() {
      return this.$d.introspect;
    },
    class_() {
      if (this.introspect && this.introspect['classes']) {
        var class_name = this.class_name;
        return this.introspect['classes'].find(class_ => {
          return class_['class_name'] == class_name;
        });
      }

      return null;
    },
    class_relationships() {
      return this.$d.getRelationships(this.class_['class_name'], 'to');
    },
    class_relationships_slotNames() {
      return _.map(this.class_relationships, relationship => relationship['from']['class_name'] + '_' + relationship['relationship_name']);
    },
    modal_component() {
      return this._modal_component || Modal;
    },
    search_component() {
      return this._search_component || Search;
    },
    enable_add_() {
      //TODO: this will force add, not matter what
      if (!_.isNil(this.enable_add)) {
        return this.enable_add;
      }

      if (this.class_['creatable'] == false) {
        return false;
      }

      if (_.get(this.class_, ['frontend', 'index', 'create', 'disabled'], false)) {
        return false;
      }

      /* if (_.get(this.class_, ['mysql_view'], false)) {
        return false;
      } */

      /* if (_.get(this.class_, ['subquery'], false)) {
        return false;
      } */

      return true;
    },
    relationship_actions_fields() {
      let relationship_actions_fields = [];

      let relationship_actions = [];

      ['to', 'from'].forEach(direction => {
          let inv_direction = this.$d.invertRelationshipDirection(direction);
        this.$d.getRelationships(this.class_['class_name'], inv_direction).forEach(relationship => {
          // let default_show = direction == 'from' ? true : false; //defaults 'from' relationships to show
          let default_show = false;


          if (_.get(relationship, [direction, 'frontend', 'relationship_action'], default_show)) {
            relationship_actions.push({
              direction: inv_direction,
              relationship: relationship,
            });
          }
        });
      });

      relationship_actions.forEach(relationship_action => {
        let relationship = relationship_action['relationship'];
        let direction = relationship_action['direction'];

        let add_action = true;

        if (relationship['mysql_view'] == true || relationship['subquery'] == true) {
          add_action = false;
        }
        if (add_action) {
          let relation_alias = this.$d.getRelationAlias(relationship, direction);
          let from_class_name = relationship['from']['class_name'];
          let relationship_name = relationship['relationship_name'];

          let label = '';

          if (direction == 'to') {
            label += _.startCase(`${relationship_name}`);
          } else {
            if (relationship['inverse_relationship_name']) {
              label += _.startCase(`${relationship['inverse_relationship_name']}`);
            } else {
              label += `${from_class_name} - ${relationship_name}`;
            }
          }

          relationship_actions_fields.push({
            key: `${relation_alias}_actions`,
            _slot_name: `${relation_alias}_actions`,
            label: label,
            tdClass: ['no_break'],
            relationship: relationship,
            direction: direction,
          });
        }
      });
      return relationship_actions_fields;
    },
    use_fields() {
      let use_fields = [];

      let extra_fields = [];
      extra_fields.push({ key: 'batch_select_column_slot', _slot_name: 'batch_select_column_slot', label: '', tdClass: ['no_break'] });
      // extra_fields.push({ key: 'id_column_slot', _slot_name: 'id_column_slot', label: 'ID', tdClass: ['no_break']}); //testing/reference purposes

      use_fields = extra_fields.concat(use_fields);

      if (this.fields) {
        use_fields = use_fields.concat(this.fields);
      } else if (this.class_name) {
        use_fields = use_fields.concat(this.$d.getClassBTableFields(this.class_name));
      }


      if (this.show_relationship_actions) {
        use_fields = use_fields.concat(this.relationship_actions_fields);
      }
      
      return use_fields;
    },
    filtered_selected_items() {
      console.log('pagination!!!!', this.pagination);
      if (!this.pagination || !this.pagination.data || this.pagination.data.length == 0) {
        return [];
      }

      let filtered_selected_items = _.filter(this.selected_items, selected_item => {
        if (_.find(this.pagination.data, { id: selected_item.id })) {
          return true;
        }
        return false;
      });
      console.log('filtered_selected_items', filtered_selected_items);
      return filtered_selected_items;
    },
  },
  created() {
    this._ = _;
    this.pluralize = pluralize;
  },
  methods: {
    get() {
      this.$refs['searchRef'].get();
    },
    openModal(params = null) {
      this.is_modal_open = true;
      if (_.isNil(params)) {
        this.modal_params = this.init_params; //TODO: procesing of init_params need to be moved into Modal.vue
      } else {
        this.modal_params = params;
      }

      setTimeout(() => {
        if (this.$refs['modalRef']) {
          this.$refs['modalRef'].open();
        }
      }, 0);

      this.$emit('onModalOpen');
    },
    addButtonClicked() {
      if (this.enable_modal && this.enable_add_modal) {
        this.openModal();
      }
      this.$emit('addButtonClicked');
    },
    rowClicked(item) {
      if (this.enable_modal && this.enable_edit_modal) {
        this.openModal(item);
      }
      this.$emit('rowClicked', item);
    },
    onModalClose(mutated) {
      this.$emit('onModalClose', mutated);
      this.is_modal_open = false;
      if (mutated) {
        if (this.reload_on_close) {
          this.get();
        } else {
          this.$emit('pending_reload');
        }
      }
    },
    getRelationshipActionRouter(action_name, item, relationship, direction) {
      // console.log('getRelationshipActionRouterLink', action_name, item, relationship, direction);

      let target_class_name = relationship[direction]['class_name'];
      let inv_direction = this.$d.invertRelationshipDirection(direction);

      let dir_relation_alias = this.$d.getRelationAlias(relationship, inv_direction);

      if (item.id) {
        let query = {};
        query[dir_relation_alias] = item.id;

        return { path: `/descriptor/${target_class_name}`, query: query };
      } else {
        throw 'relationshipAction item has no id';
      }
    },
    addItem(add_item) {
      let item = Object.assign({}, add_item); //avoid object propagation

      let added_item = _.find(this.selected_items, { id: item['id'] });
      if (!added_item) {
        this.selected_items.push(item);
        // console.log(item, this.selected_items)
      } else {
        console.log('Item added already', item['id']);
      }
    },
    addAll(items = null) {
      if (!items) {
        items = this.pagination.data;
      }
      items.forEach(item => {
        this.addItem(item);
      });
    },
    removeItem(remove_item) {
      let item = Object.assign({}, remove_item); //avoid object propagation

      let added_item_index = _.findIndex(this.selected_items, { id: item['id'] });

      this.selected_items.splice(added_item_index, 1);
      this.$emit('removed', item);
    },
    removeAll() {
      this.selected_items = [];

      //TODO: Consider clearing up error messages
      // this.batch_request_error_message = ''; //makes sure no error messages from previous
      // this.batch_request_success_message = ''; //makes sure no error messages from previous
      // this.batch_request_response = {};
    },
    batchRequestSelected(request_type) {
      this.batch_request_loading = true;

      let batch_params = {};

      if (request_type == 'delete' || request_type == 'email') {
        batch_params = {
          requests: _.map(this.filtered_selected_items, (selected_item, index) => {
            return {
              request_type: request_type == 'delete' ? 'delete' : 'email',
              request_id: index + 1,
              request_params: {
                _class_name: this.class_name,
                id: selected_item['id'],
              },
            };
          }),
        };
      }

      this.batch_request_error_message = ''; //makes sure no error messages from previous
      this.batch_request_success_message = ''; //makes sure no error messages from previous
      this.batch_request_response = {};
      this.$d.batchRequest(
        batch_params,
        result_data => {
          console.log(result_data);

          let error_count = 0;
          let success_count = 0;
          result_data['request_results'].forEach(request_result => {
            let request_id = request_result['request_id'];

            let request = _.find(batch_params['requests'], {
              request_id: request_id,
            });
            console.log(request, request_result);
            let request_params = request['request_params'];

            if (request_result['errors']) {
              error_count++;

              let request_error_message = '';
              request_error_message += `${request['request_type']} - ${request_params['_class_name']} ID: ${request_params['id']}`;
              request_error_message += '</br>';
              request_error_message += '</br>';

              let error_message_html = '';
              _.forEach(request_result['errors'], function(message, index) {
                if (index != 0) {
                  error_message_html += '</br>';
                }
                error_message_html += message;
              });
              request_error_message += error_message_html;

              ///
              this.$set(this.batch_request_response, request_params['id'], {
                error_message: request_error_message,
              });
              ///
              /* this.batch_request_error_message += request_error_message;
              this.batch_request_error_message += '</br>';
              this.batch_request_error_message += '</br>'; */
            } else {
              success_count++;

              let request_success_message = '';
              request_success_message += `${request['request_type']} - ${request_params['_class_name']} ID: ${request_params['id']}`;

              ///
              this.$set(this.batch_request_response, request_params['id'], {
                success_message: request_success_message,
              });
              ///
              /* this.batch_request_success_message += request_success_message;
              this.batch_request_success_message += '</br>';
              this.batch_request_success_message += '</br>'; */
            }
          });

          if (success_count == batch_params['requests'].length) {
            this.batch_request_success_message += success_count + '/' + batch_params['requests'].length + ' Success';
            this.batch_request_success_message += '</br>';
          } else {
            this.batch_request_error_message += error_count + '/' + batch_params['requests'].length + ' Errors';
            this.batch_request_error_message += '</br>';
            if (success_count) {
              this.batch_request_error_message += success_count + '/' + batch_params['requests'].length + ' Success';
              this.batch_request_error_message += '</br>';
            }
          }

          let unknown_count = batch_params['requests'].length - success_count - error_count;
          if (unknown_count > 0) {
            this.batch_request_error_message += unknown_count + '/' + batch_params['requests'].length + ' Unknown (contact system administrator)';
            this.batch_request_error_message += '</br>';
          }

          // this.removeAll(); //this.selected_items = []; //TODO: recover selected items, if still in the page? or force include
          // this.get();
        },
        (error_message, cancelled) => {
          console.error(error_message);

          if (error_message) {
            this.batch_request_error_message = error_message;
          }

          if (cancelled) {
            //do nothign if cancelled
          } else if (!this.batch_request_error_message) {
            this.batch_request_error_message = this.$api.defaultErrorMessage;
          }
        },
        () => {
          this.batch_request_loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
