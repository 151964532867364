<template>
  <div>
    <Search
      ref="searchRef"
      :default_per_page="5"
      :fields="$d.getClassBTableFields(index_item['class_name'])"
      :class_name="index_item['class_name']"
      :_index_params="filter"
      :allow_bottom_pagination="false"
      small
      :allow_relationships_filter="false"
      :allow_map="false"
      :enable_map_default="false"
      :allow_select_fields="false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import Search from './Search';

export default {
  name: 'IndexView',
  components: {
    Search,
  },
  props: {
    index_item: {
      type: Object,
    },
    sourceParams: {
      type: Object,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      filter: {},
    };
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
  },
  watch: {
    sourceParams: {
      deep: true,
      handler: function(newVal, oldVal) {
        this.setFilterAndGetDebounced();
      },
    },
  },
  created() {
    this.setFilterAndGetDebounced = _.debounce(this.setFilterAndGet, 3000);

    if (this.sourceParams['id']) {
      console.log('sourceParams id', this.sourceParams['id']);
      // this.getPdfById(this.sourceParams['id']);
    } else {
      // this.getPdf('preview', this.sourceParams);
    }
    console.log('sourceParams', this.sourceParams);
  },
  mounted() {
    this.setFilter(this.sourceParams);
  },
  methods: {
    setFilterAndGet() {
      this.setFilter(newVal);
      this.get();
    },
    setFilter(params) {
      this.index_item['parameter_mapping'].forEach(parameter_map => {
        var parameter_name = parameter_map['parameter'];
        if (!_.isNil(parameter_map['value'])) {
          this.filter[parameter_name] = parameter_map['value'];
        } else if (parameter_map['property_key']) {
          if (parameter_map['property_key'] == '_self') {
            this.filter[parameter_name] = params;
          } else {
            this.filter[parameter_name] = params[parameter_map['property_key']];
          }
        }
      });

      console.log('filter', this.filter);
      // this.get(); //TODO: temporarily disable auto update , it's just too slow for route grouping
    },
    get() {
      return this.$refs['searchRef'].get_undebounced();
    },
    getSnakeCase(name) {
      return _.snakeCase(name);
    },
    getStartCase(text) {
      return _.startCase(text);
    },
  },
};
</script>

<style scoped>
.b-table.b-table-stacked >>> td {
  border-top: none !important;
}
</style>
