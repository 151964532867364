const InvoiceYearMonthData = {
  chart_options: {
    x_axes:  [
      {
        // gridLines: { drawOnChartArea: false },
      }
    ],
    y_axes: [
      { id: 'RM' },
      { id: 'Invoices' },
    ],
  },
  x_config: {
    property_key: 'id',
  },
  y_config: [
    {
      property_key: 'sum_total_price',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'sum_total_price_without_tax',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'sum_total_tax',
      y_axis_ID: 'RM',
      type: 'bar',
    },
    {
      property_key: 'count',
      y_axis_ID: 'Invoices',
    },
    {
      property_key: 'count_is_cleared',
      y_axis_ID: 'Invoices',
    },
    {
      property_key: 'count_is_not_cleared',
      y_axis_ID: 'Invoices',
    },
  ],
};

export default InvoiceYearMonthData;
