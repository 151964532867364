var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"properties_view"},_vm._l((_vm.rendered_properties),function(property){return _c('b-form-group',{key:property['property_name'],attrs:{"label-cols":12,"label-cols-sm":3}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],attrs:{"slot":"label","title":property['description'] ? property['description'] : null},slot:"label"},[_vm._v("\n      "+_vm._s(property['property_name'])+"\n      "),(property['description'])?[_vm._v("\n         \n        "),_c('i',{staticClass:"fas fa-info-circle"})]:_vm._e()],2),_c('FormInput',{attrs:{"propertyType":property['type'],"number_config":{
        scale: _vm._.get(property, ['rules', 'default', 'scale'], 1),
        min: _vm._.get(property, ['rules', 'default', 'min']),
        max: _vm._.get(property, ['rules', 'default', 'max']),
      },"text_config":{
        textarea: _vm._.get(property, ['frontend', 'textarea'], false),
        rich_text_editor: _vm._.get(property, ['frontend', 'rich_text_editor'], false),
        color_picker: _vm._.get(property, ['frontend', 'color_picker'], false),
      },"base64_config":{
        mimetypes: _vm._.get(property, ['rules', 'default', 'mimetypes'], []), //TODO: need to also support complex rules
        mimetype: _vm._.get(property, ['rules', 'default', 'mimetype'], null),
        allow_crop: _vm._.get(property, ['frontend', 'allow_crop'], false),
        circle: _vm._.get(property, ['frontend', 'circle'], false),
      },"crop_config":{
        width: _vm._.get(property, ['frontend', 'crop', 'width'], 200),
        height: _vm._.get(property, ['frontend', 'crop', 'height'], 200),
      },"extParam":_vm.params[property['property_key']],"file_tempurl_param":_vm.$d.getFileTempurlParam(property, _vm.params),"prefix":property['prefix'],"suffix":property['suffix'],"disabled":_vm.disabled || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true},on:{"update:extParam":function($event){return _vm.$set(_vm.params, property['property_key'], $event)},"update:ext-param":function($event){return _vm.$set(_vm.params, property['property_key'], $event)}}})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }