<template>
  <div class="dropdowns_view">
    <b-form-group
      v-for="relationship in dropdown_relationships"
      :key="relationship['relationship_name']"
      :label-cols="12"
      :label-cols-sm="3"
      :label="getRelationshipLabel(relationship)"
    >
      <b-input-group>
        <Dropdown
          ref="dropdownRef"
          @reload="
            params => {
              reload(params);
            }
          "
          :relationship="relationship"
          :params.sync="params"
          :dropdown_relationships="dropdown_relationships"
          :disabled="disabled || $d.relIsViewOnly(relationship) || (fixed_params && fixed_params[$d.getRelationAlias(relationship)] != null)"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  components: {
    Dropdown: () => import('./Dropdown'),
  },
  props: {
    relationships: {
      type: Array,
    },
    fixed_params: {
      type: Object,
    },
    params: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      relationship_options: {},
    };
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    dropdown_relationships() {
      return this.$d.filterDropdownRelationships(this.relationships);
    },
  },
  watch: {},
  created() {
    this._ = _;
  },
  mounted() {},
  methods: {
    getRelationshipLabel(relationship) {

      let label = _.get(relationship, ['from', 'frontend', 'relationship_label']) || _.get(relationship, ['from', 'label']) 
      let relationship_name = relationship['relationship_name']

      if(label){
        return `${label} (${_.startCase(relationship_name)})`
      }

      return _.startCase(relationship_name)
    },
    reload(params) {
      this.$refs['dropdownRef'].forEach(dropdown => {
        dropdown.$forceUpdate();
      });

      if (params) {
        this.params = params;
        this.$emit('reload', params);
      }
    },
  },
};
</script>

<style scoped>
.dropdowns_view >>> .col-form-label {
  font-weight: bold;
}
</style>
