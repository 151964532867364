<template>
  <div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" v-if="showModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-dark text-light">
            <h6 class="modal-title">{{ title }}</h6>
          </div>
          <div class="modal-body">
            <div class="message-text">{{ message }}</div>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <b-button-toolbar class="float-left">
                <b-button-group class="mx-1">
                  <b-btn variant="danger" @click.stop="onConfirm">{{ confirm_text }}</b-btn>
                </b-button-group>
              </b-button-toolbar>
              <b-button-toolbar class="float-right">
                <b-button-group class="mx-1">
                  <b-btn variant="light" @click.stop="onCancel">{{ cancel_text }}</b-btn>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationModal',
  data: () => {
    return {
      showModal: false,
    };
  },
  props: {
    cancel_text: {
      type: String,
      default: 'Cancel',
    },
    confirm_text: {
      type: String,
      default: 'Confirm',
    },
    message: {
      type: String,
      default: 'Are you sure?',
    },
    title: {
      type: String,
      default: 'Confirmation',
    },
  },
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.showModal = false;
      }
    });
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    onConfirm() {
      this.$emit('onConfirm');
      this.showModal = false;
    },
    onCancel() {
      this.showModal = false;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: block !important;
  background: rgba(0, 0, 0, 0.5);
  .message-text {
    padding: 10px;
    font-size: 16px;
  }
}
</style>
