<template>
  <div class="relationships_tabs">
    <b-row>
      <b-col cols="12" :xl="allow_columns && tab_relationships.length > 0 ? 12 : 12">
        <div v-if="flat_relationships.length > 0">
          <!-- <hr> -->
          <div v-for="(relationship, index) in flat_relationships" :key="index">
            <slot
              :name="relationship['from']['class_name'] + '_' + relationship['relationship_name']"
              :relationship="relationship"
              :params.sync="params"
              :disabled="disabled || $d.relIsViewOnly(relationship)"
            >
              <div v-if="true || relationCount(relationship) !== null">
                <hr v-if="index > 0" />
                <RelView
                  :_search_component="_rel_search_components ? (_rel_search_components[relationship['from']['class_name'] + '_' + relationship['relationship_name']]) : null"
                  :relationship="relationship"
                  :params.sync="params"
                  :fixed_params="fixed_params"
                  :disabled="disabled"
                  :find_button_visibility="find_button_visibility"
                  :title="getTabTitle(relationship)"
                  :_show_selecting_search.sync="show_selecting_search_states[relationship['from']['class_name'] + '_' + relationship['relationship_name']]"
                />
              </div>
              <div v-else>
                <div class="alert alert-dark" role="alert">Not Loaded</div>
              </div>
            </slot>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :xl="allow_columns && flat_relationships.length > 0 ? 12 : 12">
        <div v-if="tab_relationships.length > 0">
          <b-tabs lazy>
            <b-tab
              v-for="(relationship, index) in tab_relationships"
              :key="index"
              :title="getTabTitle(relationship)"
              :title-link-class="titleLinkClass(relationship)"
            >
              <slot
                :name="relationship['from']['class_name'] + '_' + relationship['relationship_name']"
                :relationship="relationship"
                :params.sync="params"
                :disabled="disabled || $d.relIsViewOnly(relationship)"
              >
                <div class="pt-3 p-2 p-sm-3">
                  <div v-if="relationCount(relationship) !== null">
                    <RelView
                      :_search_component="_rel_search_components ? (_rel_search_components[relationship['from']['class_name'] + '_' + relationship['relationship_name']]) : null"
                      :relationship="relationship"
                      :params.sync="params"
                      :fixed_params="fixed_params"
                      :disabled="disabled"
                      :find_button_visibility="find_button_visibility"
                      :_show_selecting_search.sync="show_selecting_search_states[relationship['from']['class_name'] + '_' + relationship['relationship_name']]"
                    />
                  </div>
                  <div v-else>
                    <div class="alert alert-dark" role="alert">Not Loaded</div>
                  </div>
                </div>
              </slot>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'RelationshipsTabs',
  components: {
    RelView: () => import('./RelView'),
  },
  props: {
    _rel_search_components: {},
    fixed_params: {
      type: Object,
    },
    relationships: {
      type: Array,
    },
    params: {
      type: Object,
      required: true,
    },
    minTabs: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allow_mysql_view: {
      type: Boolean,
      default: true,
    },
    allow_columns: {
      type: Boolean,
      default: true,
    },
    find_button_visibility: {
      type: Object,
    },
  },
  data: () => {
    return {
      show_selecting_search_states: {},
    };
  },
  mounted() {},
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    real_relationships() {
      return this.relationships.filter(relationship => {
        if (_.get(relationship, ['frontend', 'hidden_in_modal']) == true || _.get(relationship, ['to', 'frontend', 'hidden_in_modal']) == true) {
          return false;
        }

        if (this.$d.relIsViewOnly(relationship)) {
          if (!this.allow_mysql_view) {
            //forcefully hide mysql_view
            return false;
          }

          let relation_alias = this.$d.getRelationAlias(relationship);
          if ((this.params[relation_alias] && this.params[relation_alias].length > 0) || this.params['id']) {
          } else {
            // return false;
          }
        }

        if (relationship['through']) {
          return false;
        }

        if (relationship['virtual']) {
          return false;
        }

        if (_.get(relationship, ['flags', 'dropdown'])) {
          return false;
        }

        return true;
      });
    },
    tab_relationships() {
      return this.real_relationships.filter(relationship => {
        let mode = _.get(relationship, ['to', 'frontend', 'mode']);
        return !mode || mode == 'tabs';
      });
    },
    flat_relationships() {
      return this.real_relationships.filter(relationship => {
        let mode = _.get(relationship, ['to', 'frontend', 'mode']);
        return mode == 'flattened';
      });
    },
  },
  watch: {},
  created() {
    this._ = _;

    //initialise relationship params
    /* this.relationships.forEach(relationship => {
      if (!Array.isArray(this.params[this.$d.getRelationAlias(relationship)])) {
        this.$set(this.params, this.$d.getRelationAlias(relationship), null); //workaround to fix reactivity
      }
    }); */
    console.log('relationship tabs params', this.params);
  },
  methods: {
    titleLinkClass(relationship) {
      let classes = [];
      if (this.$d.relIsViewOnly(relationship)) {
        classes.push('virtual_tab');
      }

      return classes;
    },
    getRelationshipLabel(relationship) {
      return _.get(relationship, ['from', 'label'], _.startCase(relationship['relationship_name']));
    },
    getTabTitle(relationship) {
      let to_relation_alias = this.$d.getRelationAlias(relationship);

      let count = '';
      if (this.params[to_relation_alias] && Array.isArray(this.params[to_relation_alias])) {
        count =
          ' (' +
          this.params[to_relation_alias].length +
          (_.has(relationship, 'to.rules.default.max') && relationship['to']['rules']['default']['max'] != 'infinite'
            ? '/' + relationship['to']['rules']['default']['max']
            : '') +
          ')';
      }

      let relationship_label = this.getRelationshipLabel(relationship);
      return _.trim(`${relationship_label} ${count}`);
    },
    relationCount(relationship) {
      let to_relation_alias = this.$d.getRelationAlias(relationship);

      let count = null;
      if (this.params[to_relation_alias] && Array.isArray(this.params[to_relation_alias])) {
        count = this.params[to_relation_alias].length;
      }

      return count;
    },
  },
};
</script>
<style scoped>
.relationships_tabs >>> .virtual_tab {
  background: #f8f8f8 !important;
}
.relationships_tabs >>> .virtual_tab.active {
  background: #f8f8f8 !important;
  /* border-color: #bbc1c5 !important; */
}

.relationships_tabs >>> .nav-tabs .nav-link {
  font-weight: 600;
  border-left-color: #dae2e9 !important;
  border-right-color: #dae2e9 !important;
  border-top-color: #dae2e9 !important;
}
.relationships_tabs >>> .nav-tabs .nav-link.active {
  font-weight: bold;
  border-left-color: #b1b6b9 !important;
  border-right-color: #b1b6b9 !important;
  border-top-color: #b1b6b9 !important;
}
.relationships_tabs >>> .tab-content .tab-pane {
  padding: 0;
}
</style>
