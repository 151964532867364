<template>
  <b-button-toolbar class="justify-content-between">
    <div>
      <b-button-group class="mx-1">
        <b-btn variant="light" @click="$emit('close')">Close</b-btn>
      </b-button-group>
      <b-button-group v-if="allow_delete" class="mx-1">
        <b-btn :disabled="loading" variant="danger" @click.stop="$emit('delete')">{{ delete_button_label }}</b-btn>
      </b-button-group>
      <!-- Copy Function is flawed, as it also copies the IDs of recursive relationships -->
      <!-- <b-button-group v-if="allow_copy" class="mx-1">
        <b-btn :disabled="loading" variant="warning" @click.stop="$emit('copy')">Copy</b-btn>
      </b-button-group> -->
      <b-button-group v-if="allow_uncopy" class="mx-1">
        <b-btn :disabled="loading" variant="warning" @click.stop="$emit('uncopy')">Uncopy</b-btn>
      </b-button-group>
    </div>
    <div>
      <q-spinner v-if="loading" class="mx-1" color="primary" size="35px" :thickness="2" />
      <b-button-group v-if="allow_reset" class="mx-1">
        <b-btn :disabled="loading" variant="light" @click="$emit('reset')">Reset</b-btn>
      </b-button-group>
      <b-button-group v-if="allow_preview" class="mx-1">
        <b-btn :disabled="loading" variant="secondary" @click="$emit('preview')">Preview</b-btn>
      </b-button-group>
      <b-button-group class="mx-1">
        <b-btn v-if="mode == 'add'" :disabled="loading" variant="success" @click.stop="$emit('add')">{{ add_button_label }}</b-btn>
        <b-btn v-if="mode == 'edit' && allow_edit" :disabled="loading" variant="success" @click.stop="$emit('edit')">{{ edit_button_label }}</b-btn>
      </b-button-group>
    </div>
  </b-button-toolbar>
</template>
<script>
import _ from 'lodash';
import { Switch as cSwitch } from '@coreui/vue';

import { QSpinner } from 'quasar';

export default {
  name: 'ButtonBar',
  components: {
    cSwitch,
    QSpinner,
  },
  props: {
    add_button_label: {
      type: String,
      default: 'Add',
    },
    edit_button_label: {
      type: String,
      default: 'Edit',
    },
    delete_button_label: {
      type: String,
      default: 'Delete',
    },
    mode: {
      type: String,
      default: '',
    },
    allow_delete: {
      type: Boolean,
      default: false,
    },
    allow_edit: {
      type: Boolean,
      default: true,
    },
    allow_copy: {
      type: Boolean,
      default: false,
    },
    allow_uncopy: {
      type: Boolean,
      default: false,
    },
    allow_reset: {
      type: Boolean,
      default: false,
    },
    allow_preview: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {},
};
</script>
