<template>
  <div class="properties_view">
    <b-form-group v-for="property in rendered_properties" :key="property['property_name']" :label-cols="12" :label-cols-sm="3">
      <div slot="label" v-b-tooltip.hover.topleft :title="property['description'] ? property['description'] : null">
        {{ property['property_name'] }}
        <template v-if="property['description']">
          &nbsp;
          <i class="fas fa-info-circle"></i>
        </template>
      </div>
      <FormInput
        :propertyType="property['type']"
        :number_config="{
          scale: _.get(property, ['rules', 'default', 'scale'], 1),
          min: _.get(property, ['rules', 'default', 'min']),
          max: _.get(property, ['rules', 'default', 'max']),
        }"
        :text_config="{
          textarea: _.get(property, ['frontend', 'textarea'], false),
          rich_text_editor: _.get(property, ['frontend', 'rich_text_editor'], false),
          color_picker: _.get(property, ['frontend', 'color_picker'], false),
        }"
        :base64_config="{
          mimetypes: _.get(property, ['rules', 'default', 'mimetypes'], []), //TODO: need to also support complex rules
          mimetype: _.get(property, ['rules', 'default', 'mimetype'], null),
          allow_crop: _.get(property, ['frontend', 'allow_crop'], false),
          circle: _.get(property, ['frontend', 'circle'], false),
        }"
        :crop_config="{
          width: _.get(property, ['frontend', 'crop', 'width'], 200),
          height: _.get(property, ['frontend', 'crop', 'height'], 200),
        }"
        :extParam.sync="params[property['property_key']]"
        :file_tempurl_param="$d.getFileTempurlParam(property, params)"
        :prefix="property['prefix']"
        :suffix="property['suffix']"
        :disabled="disabled || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true"
      ></FormInput>
    </b-form-group>
  </div>
</template>
<script>
import _ from 'lodash';
import FormInput from './FormInput';

export default {
  name: 'PropertiesView',
  components: {
    FormInput,
  },
  props: {
    properties: {
      type: Array,
    },
    params: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    is_virtual: {
      type: Boolean,
      default: false,
    },
    with_virtual: {
      type: Boolean,
      default: false,
    },
    only_virtual_properties: {},
    select_property_keys: {
      type: Array,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    is_show() {
      if (this.is_virtual) {
        return _.isNil(this.params['id']) ? false : true;
      }
      return true;
    },
    rendered_properties() {
      let rendered_properties = [];
      if (!this.select_property_keys) {
        let filtered_properties = this.properties;
        filtered_properties = filtered_properties.filter(property => {
          return !property['virtual'] && !property['hidden_in_modal'];
        });

        // console.log(filtered_properties);

        let real_properties = filtered_properties.filter(property => !property['virtual_property']);
        let virtual_properties = filtered_properties.filter(property => property['virtual_property']);

        if (Array.isArray(this.only_virtual_properties)) {
          // console.log('rererr');
          virtual_properties = virtual_properties.filter(property => {
            let is_include = this.only_virtual_properties.includes(property['property_key']);
            // console.log(this.only_virtual_properties, property['property_key'], is_include);
            return is_include;
          });
          // console.log(virtual_properties);
        }

        if (this.is_virtual) {
          rendered_properties = virtual_properties;
        } else {
          if (this.with_virtual && !_.isNil(this.params['id'])) {
            // rendered_properties = filtered_properties;
            // rendered_properties = this.properties.filter(property => !property['virtual']);
            // rendered_properties.concat(virtual_properties)

            rendered_properties = real_properties.concat(virtual_properties);
          } else {
            rendered_properties = real_properties;
          }
        }
      } else {
        this.select_property_keys.forEach(select_property_key => {
          let select_property = _.find(this.properties, { property_key: select_property_key });
          rendered_properties.push(select_property);
        });
      }

      return rendered_properties;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this._ = _;
  },
  methods: {},
};
</script>

<style scoped>
.properties_view >>> .col-form-label {
  font-weight: bold;
}
</style>
