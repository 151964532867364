<template>
  <div>
    <!-- <b-container fluid> -->
    <!-- <div
      class="alert alert-warning"
      role="alert"
    >Enter valid data and a preview will be generated automatically</div>-->
    <div v-if="!enable_auto_reload && is_source_changed" class="alert alert-warning" role="alert">
      Input data changed, click "Reload Preview" to re-generate preview (not saved)
    </div>
    <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
    <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
    <b-row class="p-3">
      <b-col>
        <b-button-toolbar class="float-right">
          <b-button-group class="mx-1">
            <b-btn v-if="sourceParams" id="preview_button" @click="getPdfPreview()" size="sm">
              <i class="fa fa-eye"></i>
              Reload Preview
            </b-btn>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-btn v-if="sourceParams['id']" variant="success" @click="getPdfSaved()" size="sm">
              <i class="fa fa-download"></i>
              Reload Saved
            </b-btn>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <!-- <b-tooltip
      target="preview_button"
      :show="!enable_auto_reload && is_source_changed"
    >Click to re-generate PDF</b-tooltip>-->
    <h5 v-if="pdf_mode == 'preview'">Preview:</h5>
    <h5 v-if="pdf_mode == 'view'">Saved:</h5>
    <div v-if="!error_message">
      <div v-if="pdf_url" style="margin-bottom: 20px">
        <b-embed type="iframe" aspect="16by9" :src="pdf_url" allowfullscreen></b-embed>
      </div>
      <div v-else>
        <div v-if="loading" class="sk-rotateplane"></div>
      </div>
    </div>
    <!-- </b-container> -->
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'PdfView',
  components: {},
  props: {
    class_: {
      type: Object,
    },
    sourceParams: {
      type: Object,
    },
    pdf_item: {
      type: Object,
    },
    _pdf_mode: {
      type: String,
    },
    enable_auto_reload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      prev_source: null,
      pdf_url: null,
      loading: false,
      last_params_used: null,
      is_source_changed: false,
    };
  },
  watch: {
    sourceParams: {
      deep: true,
      handler(to, from) {
        // console.log('sourceParams change check', to, this.last_params_used);
        if (this.last_params_used && !_.isEqual(to, this.last_params_used)) {
          console.log('sourceParams changed from PdfView.vue');
          if (this.enable_auto_reload) {
            this.getPdfPreviewDebounced(to, from);
          } else {
            this.is_source_changed = true;
          }
        } else {
          this.is_source_changed = false;
        }
      },
    },
  },
  computed: {
    pdf_mode: {
      // getter
      get: function() {
        return this._pdf_mode;
      },
      // setter
      set: function(newValue) {
        this.$emit('update:_pdf_mode', newValue);
      },
    },
  },
  created() {
    this.getPdfPreviewDebounced = _.debounce(this.getPdfPreview, 3000);
    this.getPdfSavedDebounced = _.debounce(this.getPdfSaved, 3000);

    if (this.sourceParams['id']) {
      this.getPdfSavedDebounced();
    } else {
      if (this.enable_auto_reload) {
        this.getPdfPreviewDebounced();
      }
    }
  },
  methods: {
    getApiParameters(pdf_item, params) {
      var api_parameters = {};
      console.log('parameter_mapping', pdf_item['parameter_mapping']);

      pdf_item['parameter_mapping'].forEach(parameter_map => {
        var parameter_name = parameter_map['parameter'];
        if (!_.isNil(parameter_map['value'])) {
          api_parameters[parameter_name] = parameter_map['value'];
        } else if (parameter_map['property_key']) {
          if (parameter_map['property_key'] == '_self') {
            api_parameters[parameter_name] = params;
          } else {
            api_parameters[parameter_name] = params[parameter_map['property_key']];
          }
        }
      });
      console.log('api_parameters', api_parameters);

      return api_parameters;
    },
    getPdf(action, params) {
      this.pdf_url = null;
      this.pdf_mode = null;
      console.log(params);

      this.$NProgress.start();
      this.loading = true;
      this.error_message = null;
      this.success_message = null;

      var config = {};

      config = {
        method: 'post',
        url: '/descriptor/' + this.class_['class_key'] + '/pdf/' + action,
        // url: "/descriptor/invoice/pdf/preview",
        // url: "/descriptor/delivery_order/pdf/preview",
        data: params,
      };

      if (this.pdf_item && this.pdf_item['pdf_api_url']) {
        config.url = this.pdf_item['pdf_api_url'];
        config.data = this.getApiParameters(this.pdf_item, params);
      }

      // this.$api(config)
      this.$api
        .once(config, this.prev_source, new_source => {
          this.prev_source = new_source;
        })
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            console.log(data);
            if (data['pdf_base64']) {
              // this.success_message = "PDF file retrieved";

              this.error_message = null; //fix for displaying PDF if clicked too fast
              this.success_message = null;
              this.pdf_url = this.$d.base64ToDataUrl(data['pdf_base64'], 'application/pdf');
              this.pdf_mode = action;
              this.$forceUpdate();
            }
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (this.$api.isCancel(axios_error)) {
            console.log('Request cancelled');
          } else {
            if (error) {
              this.error_message = this.$api.getValidation(error);
            } else {
              this.error_message = this.$api.defaultErrorMessage;
            }
          }
        })
        .then(() => {
          this.loading = false;
          this.$NProgress.done();
          // if (!this.pdf_url) {
          //   this.error_message = this.$api.defaultErrorMessage;
          // }
        });
    },
    getPdfPreview() {
      this.is_source_changed = false;
      this.last_params_used = _.cloneDeep(this.sourceParams);
      this.getPdf('preview', this.sourceParams);
    },
    getPdfSaved() {
      this.is_source_changed = false;
      this.last_params_used = _.cloneDeep(this.sourceParams);
      this.getPdf('view', { id: this.sourceParams['id'] });
      // this.getPdf('view', this.sourceParams);
    },
  },
};
</script>

<style scoped>
.b-table.b-table-stacked >>> td {
  border-top: none !important;
}

/* Spiner  */
/*  http://tobiasahlin.com/spinkit/ */
.sk-rotateplane {
  width: 40px;
  height: 40px;
  background-color: #333;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
</style>
