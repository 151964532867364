<template>
  <div>
    <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
    <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
    <b-button-toolbar class="justify-content-between">
      <div>
        <!-- <b-button-group class="mx-1">
          <b-btn variant="primary" @click.stop="performAction(sourceParams)">{{ action_item['action_name'] }}</b-btn>
        </b-button-group> -->
      </div>
      <div>
        <q-spinner v-if="loading" class="mx-1" color="primary" size="35px" :thickness="2" />
        <b-button-group class="mx-1">
          <b-btn :disabled="loading" variant="primary" @click.stop="performAction(sourceParams)">{{ action_item['action_name'] }}</b-btn>
        </b-button-group>
      </div>
    </b-button-toolbar>
    <div v-if="action_info">
      <br />
      <div class="alert alert-info" role="alert">{{ action_info }}</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { QSpinner } from 'quasar';

export default {
  name: 'ActionView',
  components: {
    QSpinner,
  },
  props: {
    class_: {
      type: Object,
    },
    sourceParams: {
      type: Object,
    },
    action_item: {
      type: Object,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      params: {},
      prev_source: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    action_info() {
      return _.get(this.action_item, ['frontend', 'info']);
    },
  },
  methods: {
    getApiParameters(parameter_mapping, params) {
      var api_parameters = {};
      console.log('parameter_mapping', parameter_mapping);

      parameter_mapping.forEach(parameter_map => {
        var parameter_name = parameter_map['parameter'];
        if (!_.isNil(parameter_map['value'])) {
          api_parameters[parameter_name] = parameter_map['value'];
        } else if (parameter_map['property_key']) {
          if (parameter_map['property_key'] == '_self') {
            api_parameters[parameter_name] = params;
          } else if (parameter_map['property_key'] == '_class_name') {
            api_parameters[parameter_name] = this.class_['class_name'];
          } else {
            api_parameters[parameter_name] = params[parameter_map['property_key']];
          }
        }
      });
      console.log('api_parameters', api_parameters);

      return api_parameters;
    },
    performAction(params) {
      console.log(params);

      this.loading = true;
      this.error_message = null;
      this.success_message = null;

      var config = {};

      config = {
        method: 'post',
        // url: '/descriptor/' + this.class_['class_key'] + '/pdf/' + action,
        data: params,
      };

      if (this.action_item && this.action_item['action_api_url']) {
        config.url = this.action_item['action_api_url'];
        config.data = this.getApiParameters(this.action_item['parameter_mapping'], params);
      }

      // this.$api(config)
      this.$api
        .once(config, this.prev_source, new_source => {
          this.prev_source = new_source;
        })
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            console.log(data);
            this.success_message = this.action_item['action_name'] + ' successful';

            this.$emit('action_success');
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (this.$api.isCancel(axios_error)) {
            console.log('Request cancelled');
          } else {
            if (error) {
              this.error_message = this.$api.getValidation(error);
            } else {
              this.error_message = this.$api.defaultErrorMessage;
            }
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.b-table.b-table-stacked >>> td {
  border-top: none !important;
}
</style>
